//TODO
// fix textarea width



import React from 'react';
import './styles/styles.scss';
import AppRouter from './Router/AppRouter'

function App() {
  return (
    <div className="App">
      <AppRouter/>
    </div>
  );
}

export default App;
